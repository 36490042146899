import React from 'react'
import './Mountain.css'
import Mountain1 from '../../assets/hills2.png'

const Mountain = () => {
  return (
    <div className="mountain">
        <img src={Mountain1} alt="" />
    </div>
  )
}

export default Mountain
