import React from 'react'
import './Newsletter.css'

const Newsletter = () => {
  return (
    <div className="newsletter">
        <div className="news-heading">
            <h1>subscribe to newsletter</h1>
            <div className="news-divider"></div>
            <div className="news-form">
            <form action="">
                <div className="email-box">
                    <input type="email" value='' placeholder='Email address'/>
                    <button>I'M IN</button>
                </div>
                
            </form>
        </div>
        </div>
        <div className="news-circle">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
       
    </div>
  )
}

export default Newsletter